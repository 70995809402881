.active-corner {
  z-index: 1;
  background-repeat: no-repeat;
  background-position: 0% 0%, 100% 0%, 100% 100%, 0% 100%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 40 40%22%3E%3Cpolygon points=%2240 0 8 0 0 0 0 8 0 40 8 40 8 8 40 8 40 0%22 fill=%22%230096d6%22 %2F%3E%3C%2Fsvg%3E"),
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 40 40%22%3E%3Cpolygon points=%2240 0 8 0 0 0 0 8 0 40 8 40 8 8 40 8 40 0%22 fill=%22%230096d6%22 transform=%22translate(40, 0) rotate(90)%22 %2F%3E%3C%2Fsvg%3E"),
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 40 40%22%3E%3Cpolygon points=%2240 0 8 0 0 0 0 8 0 40 8 40 8 8 40 8 40 0%22 fill=%22%230096d6%22 transform=%22translate(40, 40) rotate(180)%22 %2F%3E%3C%2Fsvg%3E"),
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 40 40%22%3E%3Cpolygon points=%2240 0 8 0 0 0 0 8 0 40 8 40 8 8 40 8 40 0%22 fill=%22%230096d6%22 transform=%22translate(0, 40) rotate(270)%22 %2F%3E%3C%2Fsvg%3E");
}

.new-markup {
  animation-name: markupIntro;
  animation-timing-function: cubic-bezier(0.64, 0, 0.78, 0);
  animation-duration: 350ms;
}

.corner {
  width: 32px;
  height: 32px;
  position: absolute;
  transform: translate(-50%, -50%);
}

.corner.ne {
  cursor: se-resize;
}

.corner.sw {
  cursor: sw-resize;
}

@keyframes markupIntro {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
